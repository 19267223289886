@use '/src/styles/variables.scss' as *;

.title_text {
  font-family: $roboto-medium;
  font-size: 22px;
  line-height: normal;
}

.visiblity_step_desc_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  gap: 8px;
}

.visibilty_desc {
  color: #333;
  text-align: center;
  font-family: $roboto-regular;
  font-size: 14px;
  line-height: 21px; /* 21px */
  padding-inline: 8px;
}

.visiblity_step_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 30px 20.5px;
}

.visiblity_cta {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.private_options_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: auto;
  padding: 1rem 0;
}

.tab_container {
  display: flex;
  min-width: 178px;
  padding: 4px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #333;

  .tab_item {
    padding: 2.5px 10px;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background: #ddd;
    }
    &.is_active {
      background: #333;
      color: #fff;
    }
  }
}

.tokens_nfts_container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 8px;
  max-width: 380px;
  padding-bottom: 18px;
  padding-inline: 15px;
}

.token_image_container {
  width: 75px;
  height: 75px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: auto;
    object-fit: contain;
  }
}

.token_name_container {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  text-align: center;
}

.token_card {
  width: 91px;
  height: 126px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    border: 1px solid #333;
  }
  &:focus {
    border: 1px solid #333;
  }
}

.token_desc_title {
  font-family: $roboto-medium;
  font-size: 16px;

  color: #333;
}

.token_desc_value {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #333;
  width: 100%;
  text-align: center;
  padding: 6px 16px;
  font-size: 18px;
  font-family: $roboto-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  .image_container {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: auto;
      height: auto;
      object-fit: contain;
    }
  }
}

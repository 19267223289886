@use '/src/styles/variables.scss' as *;
.animated_stepper_line {
  background-color: rgb(209 213 219);
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(0 0 0);
    height: 0px;
    transition: all 1ms ease-in-out;
  }
  &.optional {
    &::before {
      border: 1px dashed white;
    }
  }
  &.animated {
    &::before {
      height: 100%;
    }
  }

  @media (max-width: 1025px) {
    &::before {
      height: 100%;
      width: 0px;
    }
    &.animated {
      &::before {
        width: 100%;
      }
    }
  }
}

.animated_stepper_circle {
  transition: all 0s ease-in-out 1ms !important;
}

.accordian_toggler {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  svg {
    rotate: 180deg;
    transition: all 0.3s ease-in-out;
  }
  &.open {
    svg {
      rotate: 0deg;
    }
  }
}

.accordian_body {
  display: none;
  &.open {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.signless_step_indicator {
  padding: 9px 10px;
  border-radius: 8px;
  background: #ffdf0a;
  color: #333;
  font-family: $roboto-regular;
  font-size: 18px;
  font-weight: 500;
  min-height: 35px;
  min-width: 112px;
  display: flex;
  align-items: center;
}

.skip_cta {
  font-family: $roboto-regular;
  font-size: 15px;
  font-weight: 400 !important;
  color: #5d5d5d;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.signless_content {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

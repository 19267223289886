@use '/src/styles/variables.scss' as *;
.accordian_toggler {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  svg {
    rotate: 180deg;
    transition: all 0.3s ease-in-out;
  }
  &.open {
    svg {
      rotate: 0deg;
    }
  }
}

.accordian_body {
  display: none;
  &.open {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.signless_step_indicator {
  border-radius: 4px;
  background: #ffdf0a;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  min-height: 35px;
  min-width: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signless_cta_mobile {
  border-bottom: 1px dashed #5d5d5d;
  cursor: pointer;
  &:hover {
    border-bottom: 1px dashed #ffdf0a;
    color: #ffdf0a !important;
  }
}

.skip_cta {
  font-family: $roboto-regular !important;
  font-size: 15px;
  font-weight: 400 !important;
  color: #5d5d5d;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.signless_content {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.signless_activated {
  margin-top: 20px;
  display: flex;
  font-family: $roboto-regular !important;
  font-size: 16px;
  font-weight: 500 !important;
  gap: 20px;
  align-items: center;
}

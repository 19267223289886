@use '../../../../../styles/variables.scss' as *;
.plugin_card {
  grid-column: span 3;
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  // min-width: 261px;
  cursor: pointer;
  &:hover {
    background: #e8ebec;
  }
  .card_title {
    font-size: 14px;
  }
  .card_desc {
    color: rgba(119, 129, 138, 1);
    font-size: 14px;
  }
  &.disabled {
    color: #a1aab1 !important;
    cursor: not-allowed;
    .card_desc {
      color: #a1aab1 !important;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    grid-column: span 8;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    grid-column: span 6;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    grid-column: span 4;
  }

  @media (min-width: 1280px) and (max-width: 1919px) {
    grid-column: span 3;
  }

  @media (min-width: 1920px) {
    grid-column: span 2;
  }
}

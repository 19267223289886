@font-face {
  font-family: 'Roboto Light';
  font-weight: 300;
  font-style: italic;
  font-display: block;
  src: url('https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Roboto Regular';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Roboto Medium';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Roboto Semi Bold';
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url('https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Roboto Bold';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2')
    format('woff2');
}

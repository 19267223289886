.dashboard_content {
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 16px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 16px 24px;
  }

  @media screen and (min-width: 768px) {
    padding: 16px 40px;
  }
}

.dasboard_main_wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  column-gap: 24px;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(22px, 1fr));
  grid-auto-columns: minmax(22px, 1fr);
  grid-template-rows: max-content;
  row-gap: 16px;

  @media (min-width: 320px) and (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(22px, 1fr));
    grid-auto-columns: minmax(22px, 1fr);
    column-gap: 16px;
  }

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(auto-fit, minmax(14.08px, 1fr));
    grid-auto-columns: minmax(14.08px, 1fr);
    column-gap: 24px;
  }

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(27.33px, 1fr));
    grid-auto-columns: minmax(27.33px, 1fr);
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(35.33px, 1fr));
    grid-auto-columns: minmax(35.33px, 1fr);
  }

  @media screen and (min-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(56.58px, 1fr));
    grid-auto-columns: minmax(56.58px, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(36.67px, 1fr));
    grid-auto-columns: minmax(36.67px, 1fr);
  }

  @media screen and (min-width: 1279px) {
    grid-template-columns: repeat(auto-fit, minmax(57.92px, 1fr));
    grid-auto-columns: minmax(57.92px, 1fr);
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(auto-fit, minmax(58px, 1fr));
    column-gap: 24px;
    grid-auto-columns: minmax(58px, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(auto-fit, minmax(71.33px, 1fr));
    grid-auto-columns: minmax(71.33px, 1fr);
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(auto-fit, minmax(84.7px, 1fr));
    grid-auto-columns: minmax(84.7px, 1fr);
  }

  @media screen and (min-width: 1800px) {
    grid-template-columns: repeat(auto-fit, minmax(101px, 1fr));
    grid-auto-columns: minmax(101px, 1fr);
  }
  @media screen and (min-width: 1920px) {
    grid-template-columns: repeat(auto-fit, minmax(111px, 1fr));
    grid-auto-columns: minmax(111px, 1fr);
  }
}

.header_wrapper {
  grid-column: 1 / -1;
}

.dashboard_main_container {
  grid-column: 1 / -1;
  overflow-y: scroll;
}

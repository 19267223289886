@keyframes expand {
  from {
    height: 0px;
  }
  to {
    height: 54px;
  }
}

@keyframes expandMobile {
  from {
    height: 0px;
  }
  to {
    height: 64px;
  }
}

.banner {
  display: block;
  overflow: hidden !important;
  height: 0px;
  animation: expand 1s ease-in-out forwards;

  @media screen and (max-width: 1025px) {
    animation: expandMobile 1s ease-in-out forwards;
  }
}

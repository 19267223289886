.file_item_wrapper {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8ebec;
  cursor: pointer;
  &:hover {
    background-color: #f8f9fa;
    padding: 12px 16px;
  }
  @media screen and (max-width: 768px) {
    padding: 12px 16px;
  }
}

.empty_state {
  padding: 12px 16px;
  display: flex;
  color: #77818a;
  gap: 16px;
  font-size: 14px;
  align-items: center;
  svg {
    color: #77818a;
  }
}

.contract_card_head {
  display: flex;
  .contract_logo {
    width: 96px;
    height: 96px;
  }
  .arrow_img {
    width: 26px;
    height: 26px;
    margin-left: auto;
  }
}

.upload_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.upload_icon_container {
  display: flex;
  width: 48px;
  height: 48px;
  background-color: #ffdf0a;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  input {
    position: absolute;
    z-index: -1;
  }

  &.arweave_icon {
    background-color: #fff;
    border-radius: 50%;
    border: 1px dashed #000;
  }
}

.file_type_badge {
  display: flex;
  gap: 10px;
  width: 124px;
  height: 48px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 0px #00000026;
  border-radius: 8px;
}

.more_badge {
  display: flex;
  gap: 10px;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 0px #00000026;
  border-radius: 8px;
}

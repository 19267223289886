@use '/src/styles/variables.scss' as *;

.custom_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  z-index: 1;
  min-width: 100px;
  min-height: 40px;
  border-radius: 8px;
  &.primary {
    background-color: #000;
    color: #fff;
  }
  &.secondary {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
  }
  &:disabled {
    background-color: rgb(170, 170, 170, 1) !important;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.loading {
    background-color: rgb(170, 170, 170, 1) !important;
    color: #fff !important;
    cursor: not-allowed;
    pointer-events: none;
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      height: 100%;
      inset: 0;
      overflow: hidden;
      z-index: -1;
      animation: buttonProgres 4s ease-in-out infinite;
    }
  }
}

@keyframes buttonProgres {
  0% {
    width: 2%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}

.dropdown_items_wrapper {
  width: 100%;
  position: absolute;
  border-radius: 4px;
  padding: 8px;
  border: 0.5px solid #e8ebec;
  background-color: #fff;
  box-shadow: 0px 4px 16px -4px #00000026;
  top: 100%;
  right: 0;
  margin-top: 4px;
  overflow: auto;
  z-index: 1000;
  height: 0px;
  display: none;
  min-width: 160px;
  width: max-content;
  &.show {
    height: auto;
    display: block;
  }
}

.custom-filter {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg)
    brightness(119%) contrast(115%);
}

.custom-filter-6 {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg)
    brightness(6%) contrast(115%);
}

.custom-filter-18 {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg)
    brightness(18%) contrast(115%);
}

.custom-filter-19 {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg)
    brightness(19%) contrast(115%);
}

@keyframes progres {
  0% {
    width: 2%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}
.progress {
  animation: progres 4s infinite ease-in-out;
}

@use '/src/styles/variables.scss' as *;
.radio_label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .radio_label_text {
    color: #1c1b1f;
    font-family: $roboto-light;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px; /* 87.5% */
  }

  input {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

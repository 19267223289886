@use '/src/styles/variables.scss' as *;

.upload_modal_container {
  background-color: #fff;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 80vh;
    margin-bottom: 4rem;
  }
}

.file_list_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-block: 15px;
  align-items: center;
}

.heading_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #1c1b1f;
  text-align: center;
}

.title_text {
  font-family: $roboto-medium;
  font-size: 22px;
  line-height: normal;
}

.desc_text {
  font-family: $roboto-regular;
  font-size: 16px;
  line-height: 24px; /* 150% */
}

.files_list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 206px;
  height: 100%;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  z-index: 2;
  padding: 10px;
  overflow: auto;
}

.close_button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.upload_steps_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.upload_modal_sub_title {
  color: #1c1b1f;
  text-align: center;
  font-family: $roboto-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.modal_left_panel {
  height: 100%;
  background-color: #ffdf0a;
  width: 231px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: solid 50px #ffdf0d;
    border-right: solid 50px transparent;
    border-left: solid 50px transparent;
    @media (min-width: 768px) {
      top: 45%;
      left: 100%;
      margin-left: -60px;
      transform: rotate(270deg);
    }
    @media (max-width: 768px) {
      bottom: 0;
      margin-bottom: -20px;
      transform: rotate(360deg);
      left: 37%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
}

.modal_right_panel {
  height: 100%;
  flex: 1;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    flex: none;
    height: max-content !important;
  }
}

.file_preview_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px;
  height: 250px;
  width: 85%;
  background-color: #fff;
  z-index: 9;
  border-radius: 8px;
  margin-top: 20px;
}

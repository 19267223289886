.responsive_grid {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 24px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(22px, 1fr));
  grid-auto-columns: minmax(22px, 1fr);

  @media screen and (min-width: 320px) {
    grid-template-columns: repeat(auto-fill, minmax(22px, 1fr));
    grid-auto-columns: minmax(22px, 1fr);
    gap: 16px;
  }

  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
    grid-auto-columns: minmax(42px, 1fr);
    gap: 16px;
  }

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(auto-fill, minmax(14.08px, 1fr));
    grid-auto-columns: minmax(14.08px, 1fr);
    gap: 24px;
  }

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(27.33px, 1fr));
    grid-auto-columns: minmax(27.33px, 1fr);
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(35.33px, 1fr));
    grid-auto-columns: minmax(35.33px, 1fr);
  }

  @media screen and (min-width: 1023px) {
    grid-template-columns: repeat(auto-fill, minmax(56.58px, 1fr));
    grid-auto-columns: minmax(56.58px, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(36.67px, 1fr));
    grid-auto-columns: minmax(36.67px, 1fr);
  }

  @media screen and (min-width: 1279px) {
    grid-template-columns: repeat(auto-fill, minmax(57.92px, 1fr));
    grid-auto-columns: minmax(57.92px, 1fr);
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(auto-fill, minmax(58px, 1fr));
    gap: 24px;
    grid-auto-columns: minmax(58px, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(71.33px, 1fr));
    grid-auto-columns: minmax(71.33px, 1fr);
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(auto-fill, minmax(84.7px, 1fr));
    grid-auto-columns: minmax(84.7px, 1fr);
  }

  @media screen and (min-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(101px, 1fr));
    grid-auto-columns: minmax(101px, 1fr);
  }
  @media screen and (min-width: 1920px) {
    grid-template-columns: repeat(auto-fill, minmax(111px, 1fr));
    grid-auto-columns: minmax(111px, 1fr);
  }
}

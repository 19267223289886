@use '/src/styles/variables.scss' as *;
.transaction_widget_container {
  position: fixed;
  right: 44px;
  bottom: 0;
  width: 375px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  @media (max-width: 460px) {
    width: 100%;
    right: 0;
  }
}

.transaction_widget_header {
  background: #ffdf0a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 7px 8px 12px;
}

.transaction_details {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-size: 18px;
  font-family: $roboto-regular;
  .transaction_type {
    font-weight: 400;
  }
  .transaction_running_status {
    font-weight: bold;
  }
}

.queue_item {
  display: flex;
  font-family: $roboto-regular;
  align-items: center;
  padding: 8px;
  .item_icon_and_name {
    display: flex;
    gap: 8px;
    align-items: center;
    .item_name {
      width: 105px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;

      &.is_link {
        cursor: pointer;
        text-decoration: underline;
        color: rgb(95, 95, 253);
      }
    }
  }
  .item_status {
    color: #777;
    font-size: 12px;
    margin-left: auto;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  &:hover {
    background-color: #f5f5f5;
  }
}

.collapse_toggler {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &.collapsed {
    transform: rotate(180deg);
  }
}

.transacton_queue {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-height: 260px;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  &.collapsed {
    max-height: 0;
  }
}

/* @import '@fileverse/ui/dist/index.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import '@fileverse/ui/dist/index.css';
@import '@radix-ui/themes/layout.css';
@import '~quill/dist/quill.snow.css';
@import './styles/custom.css';

@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Press Start 2P';
  font-style: normal;
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/pressstart2p/v15/e3t4euO8T-267oIAQAu6jDQyK3nVivNm4I81.woff2')
    format('woff2');
}

* {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
}

body {
  margin: 0;
  background: #ffffff;
  font-family: 'Roboto', 'Helvetica Neue', 'Lato', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'JetBrainsMono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
input:focus {
  outline: none;
}
table.dapp-table {
  border-collapse: separate;
  border-spacing: 0;
}
table.dapp-table tr th,
table.dapp-table tr td {
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 1rem;
}
table.dapp-table tr th:first-child,
table.dapp-table tr td:first-child {
  border-left: 1px solid #bbb;
}
table.dapp-table tr th {
  border-top: 1px solid #bbb;
  text-align: left;
}

/* top-left border-radius */
table.dapp-table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table.dapp-table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table.dapp-table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table.dapp-table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.tab-h-d {
  height: calc(66vh - 8px);
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  @apply w-1 h-1 bg-neutral-500/20;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-500/50 rounded-full;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.ql-container,
.ql-editor {
  height: auto;
}
.ql-editor.ql-blank {
  font-size: 24px;
  font-style: normal;
  opacity: 40%;
}
.ql-snow.ql-toolbar {
  border: none;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: auto;
  height: auto;
  padding: 0;
}
.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  width: 16px;
  height: 16px;
}
.ql-snow.ql-toolbar select,
.ql-snow .ql-toolbar select {
  width: auto;
  height: auto;
  padding: 0;
}
.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  width: 24px;
  height: auto;
}
.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  display: none;
}
.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 6px;
  px: 16px;
  py: 15px;
  padding: 8px;
}

input[type='radio'] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid #333;
  border: 2px solid white;
  border-radius: 2px;
  width: 16px;
  height: 16px;
}

input[type='radio']:checked {
  background: #333;
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}

.changeWhite {
  filter: invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg)
    brightness(119%) contrast(115%);
}
.hr-lines {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #a2a2a2;
  border-style: dashed;
  line-height: 0.1em;
  margin: 10px 0 10px;
}

.hr-lines span {
  background: #fff;
  padding: 0 10px;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

h1.ce-header {
  font-size: 2em;
  font-weight: bold;
}
h2.ce-header {
  font-size: 1.5em;
  font-weight: bold;
}
h3.ce-header {
  font-size: 1.17em;
  font-weight: bold;
}
h4.ce-header {
  font-size: 1em;
  font-weight: bold;
}
h5.ce-header {
  font-size: 0.83em;
  font-weight: bold;
}
h6.ce-header {
  font-size: 0.75em;
  font-weight: bold;
}
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.button {
  position: relative;
  width: 50px;
  height: 30px;
  overflow: hidden;
}
.button.r,
.button.r .layer {
  border-radius: 100px;
}
.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #777777;
  transition: 0.3s ease all;
  z-index: 1;
}
#button-3 .knobs:before {
  content: 'ON';
  position: absolute;
  top: 2px;
  left: 4px;
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}
#button-3 .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

#button-3 .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

#button-3 .checkbox:checked + .knobs:before {
  content: 'OFF';
  left: 22px;
  background-color: #ffffff;
}

#button-3 .checkbox:checked ~ .layer {
  background-color: #000000;
}

.ProseMirror > * + * {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
  margin: 0.5rem;
  text-wrap: text-wrap;
  word-break: break-all;
  width: inherit;
}
.ProseMirror li {
  padding: 0 1rem;
  margin: 0.5rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3 {
  line-height: 1.1;
  font-weight: bold;
}
.ProseMirror h1 {
  font-size: 32px;
}
.ProseMirror h2 {
  font-size: 24px;
}
.ProseMirror h3 {
  font-size: 18px;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 1px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror img:hover {
  @apply hover:shadow-lg transition-shadow duration-200 cursor-pointer;
}
.ProseMirror img.ProseMirror-selectednode {
  outline: 3px solid #5abbf7;
  filter: brightness(90%);
}

.tiptap.ProseMirror ol {
  display: flex;
  flex-direction: column;
}

ul[data-type='taskList'] li > label {
  margin-right: 0.2rem;
  user-select: none;
}

@media screen and (max-width: 768px) {
  ul[data-type='taskList'] li > label {
    margin-right: 0.5rem;
  }
}

ul[data-type='taskList'] li > label input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 3px;
  border: 2px solid black;
  margin-right: 0.3rem;
  display: grid;
  place-content: center;
}
ul[data-type='taskList'] li > label input[type='checkbox']:hover {
  background-color: #f8f9fa;
}
ul[data-type='taskList'] li > label input[type='checkbox']:active {
  background-color: #e9ecef;
}
ul[data-type='taskList'] li > label input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em;
  transform-origin: center;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
ul[data-type='taskList'] li > label input[type='checkbox']:checked::before {
  transform: scale(1);
}

ul[data-type='taskList'] li[data-checked='true'] > div > p {
  color: #a8a29e;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}
.custom-input {
  border: 0.5px solid transparent;
  border-radius: 4px;
  padding: 0.3rem;
  transition: all 0.3s ease;
}
.custom-input:hover {
  outline: none;
  border-color: #777;
  box-shadow: none !important;
}
.custom-input:focus {
  border: 1px solid #ffdf0a;
}
ol li {
  float: left;
  margin-right: 30px;
}

/* EditorJS / dPages custom CSS  */
.ce-block__content,
.ce-toolbar__content {
  max-width: unset;
}

.ce-block__content .cdx-block.cdx-list.cdx-list--ordered {
  display: flex;
  flex-direction: column;
}

.tc-table {
  overflow: scroll;
}

.tc-row {
  display: flex !important ;
}

.tc-cell {
  width: 325px;
  overflow-y: scroll !important;
  max-height: 125px;
}

.codex-editor__redactor {
  padding-bottom: 300px !important;
}

.cdx-block a {
  color: #0000ee !important;
}

svg.icon--link {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='0.5em' height='0.5em' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M7.5 17.5q-2.3 0-3.9-1.6T2 12q0-2.3 1.6-3.9t3.9-1.6H18q1.65 0 2.825 1.175T22 10.5q0 1.65-1.175 2.825T18 14.5H8.5q-1.05 0-1.775-.725T6 12q0-1.05.725-1.775T8.5 9.5H18V11H8.5q-.425 0-.713.288T7.5 12q0 .425.288.713T8.5 13H18q1.05 0 1.775-.725T20.5 10.5q0-1.05-.725-1.775T18 8H7.5Q5.85 8 4.675 9.175T3.5 12q0 1.65 1.175 2.825T7.5 16H18v1.5H7.5Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;
}
svg.icon--unlink {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='0.5em' height='0.5em' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M7.5 17.5q-2.3 0-3.9-1.6T2 12q0-2.3 1.6-3.9t3.9-1.6H18q1.65 0 2.825 1.175T22 10.5q0 1.65-1.175 2.825T18 14.5H8.5q-1.05 0-1.775-.725T6 12q0-1.05.725-1.775T8.5 9.5H18V11H8.5q-.425 0-.713.288T7.5 12q0 .425.288.713T8.5 13H18q1.05 0 1.775-.725T20.5 10.5q0-1.05-.725-1.775T18 8H7.5Q5.85 8 4.675 9.175T3.5 12q0 1.65 1.175 2.825T7.5 16H18v1.5H7.5Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center, 50%, 50%;
}

button.ce-inline-tool--link:has(svg) {
  display: none;
}
button.ce-inline-tool--link:has(svg.icon--link) {
  display: block !important;
}
button.ce-inline-tool--link:has(svg.icon--unlink) {
  display: block !important;
}
.ce-inline-tool-hyperlink--select-target {
  display: none !important;
}
.ce-inline-tool-hyperlink--select-rel {
  display: none !important;
}

.cdx-block.embed-tool .cdx-input.embed-tool__caption {
  display: none;
}

body > iframe {
  display: none;
}

.reactour__popover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.reactour__popover > span,
.reactour__popover > button,
div.reactour__popover > div:last-child {
  display: none !important;
}
@keyframes shadowGrowShrink {
  0%,
  100% {
    box-shadow: 0 0 5px 0 #ffdf0a;
  }
  50% {
    box-shadow: 0 0 20px 2px #ffdf0a;
  }
}

.animate-shadow {
  animation: shadowGrowShrink 1.5s infinite;
}

.excalidraw {
  font-family: 'Helvetica Neue', 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif !important;
}

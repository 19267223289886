@use '/src/styles/variables.scss' as *;

.file_item {
  display: flex;
  align-items: center;
  font-family: $roboto-regular;
  &.has_error {
    color: red;
    .file_icon_and_name {
      .file_name {
        color: red;
      }
    }
  }
  .file_icon_and_name {
    display: flex;
    gap: 8px;
    align-items: center;
    .file_name {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
  }
  .file_size {
    margin-left: auto;
  }

  .file_info_icon {
    margin-left: auto;
    svg {
      width: 16px;
    }
  }
}

.task_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 15px;
  grid-template-rows: max-content;
  padding: 20px;
}

.fakemasonry {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  height: fit-content;
  // max-height: 900px;
}

.fakemasonry::before,
.fakemasonry::after {
  content: '';
  flex-basis: 100%;
  width: 0;
  order: 2;
}

.item {
  box-sizing: border-box;
  width: calc(50% - 20px);
  margin: 10px;
  padding: 1em;
}

.item:nth-child(2n + 1) {
  order: 1;
}
.item:nth-child(2n) {
  order: 2;
}

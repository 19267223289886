.portals_wrapper {
  width: 76.4rem; // 1222px approx
  max-width: 100%;
  padding: 1rem 1.75rem; //16px //28px
  overflow: auto !important;
  @media (max-width: 1025px) {
    width: 100%;
  }
  @media (max-width: 449px) {
    padding: 0rem 0.5rem;
  }
}

.portals_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 28px;
  grid-template-rows: max-content;
  @media (max-width: 1259px) {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  @media (max-width: 449px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

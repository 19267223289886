$heart-width: 162px;
$heart-background: transparent;
$heart-fill: red; /*#F30000; */
$transition-duration: 6s;
$transition-timing-function: forwards;
$border: 78px 132px #000000, 84px 126px #000000, 90px 120px #000000,
  96px 114px #000000, 102px 108px #000000, 108px 102px #000000,
  108px 96px #000000, 114px 90px #000000, 114px 84px #000000, 114px 78px #000000,
  108px 72px #000000, 102px 66px #000000, 90px 66px #000000, 96px 66px #000000,
  84px 72px #000000, 78px 78px #000000, 72px 72px #000000, 66px 66px #000000,
  60px 66px #000000, 54px 66px #000000, 48px 72px #000000, 42px 84px #000000,
  42px 78px #000000, 42px 90px #000000, 48px 96px #000000, 48px 102px #000000,
  54px 108px #000000, 60px 114px #000000, 66px 120px #000000, 72px 126px #000000;
$shine: 54px 72px white, 48px 78px white;
$line0: 78px 126px $heart-background;
$line1: 84px 120px $heart-background, 78px 120px $heart-background,
  72px 120px $heart-background;
$line2: 90px 114px $heart-background, 84px 114px $heart-background,
  78px 114px $heart-background, 72px 114px $heart-background,
  66px 114px $heart-background;
$line3: 96px 108px $heart-background, 90px 108px $heart-background,
  84px 108px $heart-background, 78px 108px $heart-background,
  72px 108px $heart-background, 66px 108px $heart-background,
  60px 108px $heart-background;
$line4: 102px 102px $heart-background, 96px 102px $heart-background,
  90px 102px $heart-background, 78px 102px $heart-background,
  84px 102px $heart-background, 72px 102px $heart-background,
  66px 102px $heart-background, 60px 102px $heart-background,
  54px 102px $heart-background;
$line5: 102px 96px $heart-background, 90px 96px $heart-background,
  96px 96px $heart-background, 84px 96px $heart-background,
  54px 96px $heart-background, 60px 96px $heart-background,
  66px 96px $heart-background, 72px 96px $heart-background,
  78px 96px $heart-background;
$line6: 108px 90px $heart-background, 102px 90px $heart-background,
  96px 90px $heart-background, 90px 90px $heart-background,
  84px 90px $heart-background, 78px 90px $heart-background,
  72px 90px $heart-background, 66px 90px $heart-background,
  60px 90px $heart-background, 54px 90px $heart-background,
  48px 90px $heart-background;
$line7: 108px 84px $heart-background, 102px 84px $heart-background,
  96px 84px $heart-background, 90px 84px $heart-background,
  84px 84px $heart-background, 78px 84px $heart-background,
  72px 84px $heart-background, 66px 84px $heart-background,
  60px 84px $heart-background, 54px 84px $heart-background,
  48px 84px $heart-background;
$line8: 108px 78px $heart-background, 102px 78px $heart-background,
  96px 78px $heart-background, 90px 78px $heart-background,
  84px 78px $heart-background, 72px 78px $heart-background,
  66px 78px $heart-background, 60px 78px $heart-background,
  54px 78px $heart-background;
$line9: 102px 72px $heart-background, 96px 72px $heart-background,
  90px 72px $heart-background, 66px 72px $heart-background,
  60px 72px $heart-background;
// Heart fill
$line0_: 78px 126px $heart-fill;
$line1_: 84px 120px $heart-fill, 78px 120px $heart-fill, 72px 120px $heart-fill;
$line2_: 90px 114px $heart-fill, 84px 114px $heart-fill, 78px 114px $heart-fill,
  72px 114px $heart-fill, 66px 114px $heart-fill;
$line3_: 96px 108px $heart-fill, 90px 108px $heart-fill, 84px 108px $heart-fill,
  78px 108px $heart-fill, 72px 108px $heart-fill, 66px 108px $heart-fill,
  60px 108px $heart-fill;
$line4_: 102px 102px $heart-fill, 96px 102px $heart-fill, 90px 102px $heart-fill,
  78px 102px $heart-fill, 84px 102px $heart-fill, 72px 102px $heart-fill,
  66px 102px $heart-fill, 60px 102px $heart-fill, 54px 102px $heart-fill;
$line5_: 102px 96px $heart-fill, 90px 96px $heart-fill, 96px 96px $heart-fill,
  84px 96px $heart-fill, 54px 96px $heart-fill, 60px 96px $heart-fill,
  66px 96px $heart-fill, 72px 96px $heart-fill, 78px 96px $heart-fill;
$line6_: 108px 90px $heart-fill, 102px 90px $heart-fill, 96px 90px $heart-fill,
  90px 90px $heart-fill, 84px 90px $heart-fill, 78px 90px $heart-fill,
  72px 90px $heart-fill, 66px 90px $heart-fill, 60px 90px $heart-fill,
  54px 90px $heart-fill, 48px 90px $heart-fill;
$line7_: 108px 84px $heart-fill, 102px 84px $heart-fill, 96px 84px $heart-fill,
  90px 84px $heart-fill, 84px 84px $heart-fill, 78px 84px $heart-fill,
  72px 84px $heart-fill, 66px 84px $heart-fill, 60px 84px $heart-fill,
  54px 84px $heart-fill, 48px 84px $heart-fill;
$line8_: 108px 78px $heart-fill, 102px 78px $heart-fill, 96px 78px $heart-fill,
  90px 78px $heart-fill, 84px 78px $heart-fill, 72px 78px $heart-fill,
  66px 78px $heart-fill, 60px 78px $heart-fill, 54px 78px $heart-fill;
$line9_: 102px 72px $heart-fill, 96px 72px $heart-fill, 90px 72px $heart-fill,
  66px 72px $heart-fill, 60px 72px $heart-fill;

@keyframes fill {
  0% {
    box-shadow: $shine, $line1, $line2, $line3, $line4, $line5, $line6, $line7,
      $line8, $line9, $border, $line0;
  }
  10% {
    box-shadow: $shine, $line1, $line2, $line3, $line4, $line5, $line6, $line7,
      $line8, $line9, $border, $line0_;
  }
  20% {
    box-shadow: $shine, $line1_, $line2, $line3, $line4, $line5, $line6, $line7,
      $line8, $line9, $border, $line0_;
  }
  30% {
    box-shadow: $shine, $line1_, $line2_, $line3, $line4, $line5, $line6, $line7,
      $line8, $line9, $border, $line0_;
  }
  40% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4, $line5, $line6,
      $line7, $line8, $line9, $border, $line0_;
  }
  50% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5, $line6,
      $line7, $line8, $line9, $border, $line0_;
  }
  60% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6,
      $line7, $line8, $line9, $border, $line0_;
  }
  70% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
      $line7, $line8, $line9, $border, $line0_;
  }
  80% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
      $line7_, $line8, $line9, $border, $line0_;
  }
  90% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
      $line7_, $line8_, $line9, $border, $line0_;
  }
  95% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
      $line7_, $line8_, $line9_, $border, $line0_;
  }
  100% {
    box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
      $line7_, $line8_, $line9_, $border, $line0_;
  }
}
.frame {
  text-align: center;
}
.heart {
  display: inline-block;
  width: 79px;
  height: 74px;
  position: relative;
  transform: scale(0.35, 0.35);
  cursor: pointer;

  @media (max-width: 1025px) {
    transform: scale(0.35, 0.35);
  }

  &::after {
    content: '';
    position: absolute;
    top: -65px;
    left: -42px;
    width: 6px;
    height: 6px;
    background: transparent;
    box-shadow: $shine, $line1_, $line2, $line3, $line4, $line5, $line6, $line7,
      $line8, $line9, $border, $line0_;
  }
  &.beat {
    animation: scale-up-down-large 3s infinite;

    @media (max-width: 1025px) {
      animation: scale-up-down-small 3s infinite;
    }
  }

  @keyframes scale-up-down-large {
    0%,
    100% {
      transform: scale(0.35, 0.35);
    }
    25%,
    75% {
      transform: scale(0.5, 0.5);
    }
  }

  @keyframes scale-up-down-small {
    0%,
    100% {
      transform: scale(0.45, 0.45);
    }
    25%,
    75% {
      transform: scale(0.35, 0.35);
    }
  }
  &.animate {
    &::after {
      animation: $transition-duration fill $transition-timing-function;
    }
  }
  &.ten {
    &::after {
      box-shadow: $shine, $line1, $line2, $line3, $line4, $line5, $line6, $line7,
        $line8, $line9, $border, $line0;
    }
  }
  &.twenty {
    &::after {
      box-shadow: $shine, $line1_, $line2, $line3, $line4, $line5, $line6,
        $line7, $line8, $line9, $border, $line0_;
    }
  }
  &.thirty {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3, $line4, $line5, $line6,
        $line7, $line8, $line9, $border, $line0_;
    }
  }
  &.forty {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4, $line5, $line6,
        $line7, $line8, $line9, $border, $line0_;
    }
  }
  &.fifty {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5, $line6,
        $line7, $line8, $line9, $border, $line0_;
    }
  }
  &.sixty {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6,
        $line7, $line8, $line9, $border, $line0_;
    }
  }
  &.seventy {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
        $line7, $line8, $line9, $border, $line0_;
    }
  }
  &.eighty {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
        $line7_, $line8, $line9, $border, $line0_;
    }
  }
  &.ninety {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
        $line7_, $line8_, $line9, $border, $line0_;
    }
  }
  &.hundred {
    &::after {
      box-shadow: $shine, $line1_, $line2_, $line3_, $line4_, $line5_, $line6_,
        $line7_, $line8_, $line9_, $border, $line0_;
    }
  }
}

@use '/src/styles/variables.scss' as *;
.text_input {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #333;
  font-family: system-ui, $roboto-regular;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.select_input_wrapper {
  position: relative;
  cursor: pointer;
  min-width: 125px;
  font-family: system-ui, $roboto-regular;
}

.select_input {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.select_toggler {
  transition: all 0.3s ease-in-out;
  &.is_open {
    transform: rotate(180deg);
  }
}

.select_options_wrapper {
  position: absolute;
  left: 0;
  top: 110%;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;

  &.is_open {
    max-height: 200px;
    border: 1px solid #333;
  }

  .select_option_item {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &:hover {
      background: #ddd;
    }
  }
}
